<!-- 基础数据统计 -->
<template>
	<div class="basicMedical">
		<div class="title">基础医疗服务统计</div>
		<div class="noDataClass w100" v-if="showNoData">
			<img src="@/assets/imgs/noData.png" alt="" />
		</div>
		<div v-show="!showNoData" id="basicMedicalData" style="width: 100%; height: 254px"></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import * as echarts from 'echarts';
import { slider } from '../common';
import { random } from '@/utils';
export default {
	data() {
		return {
			showNoData: false,
		};
	},
	mounted() {
		this.init();
	},
	computed: {
		...mapState('mapData', ['mapDataArr']),
	},
	watch: {
		mapDataArr: {
			handler(val) {
				if (val.length === 0) {
					this.showNoData = true;
					return;
				}
				this.init();
			},
			deep: true,
		},
	},
	methods: {
		init() {
			let arrData = this.mapDataArr.map((d) => {
				return { regionName: d.regionName, medicalOrganizations: random(0, 20), medicalWorkers: random(0, 50), people: random(0, 100) };
			});
			let basicMedicalData = echarts.init(document.getElementById('basicMedicalData'));
			basicMedicalData.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				legend: {
					data: ['家庭签约医生', '远程医疗', '儿童健康'],
					align: 'left',
					right: 10,
					top: 18,
					textStyle: {
						color: '#fff',
					},
					itemWidth: 10,
					itemHeight: 10,
					itemGap: 35,
				},
				grid: {
					left: 7,
					right: 16,
					bottom: 16,
					top: 48,
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: arrData.map((item) => item.regionName),
						axisLine: {
							show: true,
							lineStyle: {
								color: '#063374',
								width: 1,
								type: 'solid',
							},
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							interval: 0,
							show: true,
							textStyle: {
								color: '#fff',
							},
							formatter: function (value) {
								return echarts.format.truncateText(value, 70, '…');
							},
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '(数量)',
						nameTextStyle: {
							color: '#fff',
							align: 'right',
						},
						axisLabel: {
							formatter: '{value}',
							color: '#fff',
						},
						axisTick: {
							show: false,
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#00c7ff',
								width: 1,
								type: 'solid',
							},
						},
						splitLine: {
							lineStyle: {
								color: '#063374',
							},
						},
					},
				],
				series: [
					{
						name: '家庭签约医生',
						type: 'bar',
						data: arrData.map((v) => v.medicalOrganizations),
						barWidth: 6, //柱子宽度
						barGap: 1, //柱子之间间距
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: '#02ADC0',
									},
									{
										offset: 1,
										color: '#50AD8C',
									},
								]),
								opacity: 1,
							},
						},
					},
					{
						name: '远程医疗',
						type: 'bar',
						data: arrData.map((v) => v.medicalWorkers),
						barWidth: 6,
						barGap: 1,
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: '#D5F79C',
									},
									{
										offset: 1,
										color: '#5AD7A1',
									},
								]),
								opacity: 1,
							},
						},
					},
					{
						name: '儿童健康',
						type: 'bar',
						data: arrData.map((v) => v.people),
						barWidth: 6,
						barGap: 1,
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: '#4AAEFF',
									},
									{
										offset: 1,
										color: '#72E4F3',
									},
								]),
								opacity: 1,
							},
						},
					},
				],
				dataZoom: slider(arrData.length),
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.basicMedical {
	border: 2px solid #264471;
	box-sizing: border-box;
	.title {
		background: url('../../../assets/imgs/titleBg.png') no-repeat center;
		background-size: 100% 100%;
		line-height: 32px;
		padding-left: 16px;
		font-size: 16px;
	}
	#basicMedicalData {
		background: linear-gradient(to bottom, #0a2a57, #092448);
	}
	.noDataClass {
		height: 254px;
		display: flex;
		justify-content: center;
		align-items: center;
		// img {
		// 	width: 100%;
		// }
	}
}
</style>