<template>
	<div
		class="carInfo"
		v-loading="carLoading"
		element-loading-text="加载中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.9)"
	>
		<div v-if="!carInfo.id" class="nullData">暂无数据</div>
		<div v-else>
			<div class="carInfo-title">
				<div class="leftBox">车辆详细信息</div>
				<div class="rightBox">
					<img @click="closeCarInfo" src="@/assets/imgs/visitCloseBtn.png" alt="" />
				</div>
			</div>
			<div class="carInfo-context">
				<div class="deviceInfo">
					<div class="imgs">
						<span>{{ carInfo.nature }}</span>
						<img v-if="carInfo.img" class="w100 h100" :src="carInfo.img" alt="" />
					</div>
					<div class="infos">
						<p class="name">{{ carInfo.nature }}车（{{ carInfo.licenseNumber }}）</p>
						<p class="type"><span>车&emsp;&emsp;型：</span>{{ carInfo.type }}</p>
						<p class="nature"><span>性&emsp;&emsp;质：</span>{{ carInfo.nature }}</p>
						<p class="person"><span style="letter-spacing: 4.7px">载人数：</span>{{ carInfo.carryingCapacity }}</p>
						<p class="practiceUnit"><span>所属单位：</span>{{ carInfo.ownerOrganizationName }}</p>
						<p class="phone"><span style="letter-spacing: 4.7px">负责人：</span>{{ carInfo.phoneNumber }}</p>
					</div>
				</div>
				<div class="certificate">
					<div class="flexImg" v-if="carInfo.otherImgs.length > 0">
						<img v-for="item in carInfo.otherImgs" :key="item.url" :src="item.url" alt="" />
					</div>
					<div class="nullImgData" v-else>未上传车辆详情图片</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			carLoading: false,
			carInfo: {},
		};
	},
	props: {
		discriminationCode: {
			type: String,
			default: 'T',
		},
	},
	methods: {
		closeCarInfo() {
			this.$emit('closeCarInfo');
		},
		// 获取数据
		getData(data) {
			if (!data.id) return this.$message.error('数据错误，请联系管理员');
			this.carLoading = true;
			// `/${data.item.id}.json`
			this.$http
				.get(`/management-api/v1/Vehicles/${data.item.id}.json`)
				.then((res) => {
					if (res.data && res.data.success) {
						this.carLoading = false;
						let data = res.data.collection[0] || {};
						this.carInfo = data;
						this.carInfo.img = (data.attachmentDtoMap && data.attachmentDtoMap['DETAILPHOTO'] && data.attachmentDtoMap['DETAILPHOTO'][0].link) || '';
						this.carInfo.type = data.modelDictionaryItem?.title || '';
						this.carInfo.nature = data.characterDictionaryItem?.title || '';
						this.carInfo.otherImgs =
							(
								data.attachmentDtoMap &&
								data.attachmentDtoMap['DETAILPHOTO'] &&
								data.attachmentDtoMap['DETAILPHOTO'].map((v) => {
									return {
										url: v.link,
									};
								})
							).splice(1) || [];
					}
				})
				.catch((err) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.carInfo {
	width: 500px;
	height: 460px;
	overflow: auto;
	background: url('../../../../assets/imgs/visitGroupBg.png') no-repeat center;
	background-size: 100% 100%;
	position: relative;
	&-title {
		height: 52px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 25px;
		background: url('../../../../assets/imgs/dialogTitleBg.png') no-repeat center;
		background-size: 100% 100%;
		color: #fff;
		.leftBox {
			margin-top: 6px;
			font-size: 22px;
			font-family: PangMenZhengDao;
			line-height: 22px;
			height: 25px;
			background: url('../../../../assets/imgs/线.png') no-repeat bottom;
			background-size: 100% 5px;
		}
		.rightBox {
			margin-top: 6px;

			img {
				width: 28px;
				height: 28px;
				cursor: pointer;
			}
		}
	}
	&-context {
		.deviceInfo {
			padding: 16px 20px;
			display: flex;
			justify-content: space-between;
			border-bottom: 2px solid #153b6c;
			.imgs {
				background: #0a2951;
				border: 1px solid #274271;
				border-radius: 6px;
				position: relative;
				width: 180px;
				height: 180px;
				margin-right: 16px;
				span {
					display: inline-block;
					padding: 0 5px;
					text-align: center;
					line-height: 24px;
					color: #fff;
					background-color: #1fcfc0;
					border-radius: 6px 0 6px 0;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			.infos {
				flex: 1;
				color: #fff;
				p {
					font-size: 14px;
					line-height: 14px;
					height: 14px;
					margin-bottom: 16px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.name {
					font-size: 16px;
					line-height: 16px;
					height: 16px;
					margin-bottom: 25px;
					font-weight: bold;
				}
			}
		}
		.certificate {
			padding: 15px 18px;
			.flexImg {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			img {
				width: 145px;
				height: 160px;
				&:last-child {
					margin-right: 0;
				}
			}
			.nullImgData {
				width: 100%;
				color: #fff;
				text-align: center;
			}
		}
	}
	.nullData {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		font-size: 16px;
	}
}
</style>