<template>
	<div
		class="person"
		v-loading="personLoading"
		element-loading-text="加载中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.9)"
	>
		<div v-if="!personInfo.id" class="nullData">暂无数据</div>
		<div v-else>
			<div class="person-title">
				<div class="leftBox">人员详细信息</div>
				<div class="rightBox">
					<p @click="openVisitHistoryFn">{{ dataObj.discriminationCode == 'T' ? '巡诊' : '驻派' }}记录</p>
					<img @click="closePerson" src="@/assets/imgs/visitCloseBtn.png" alt="" />
				</div>
			</div>
			<div class="person-context">
				<div class="personInfo">
					<div class="personInfo-doctor">
						<div class="imgs">
							<img v-if="personInfo.img" :src="personInfo.img" alt="" />
						</div>
						<div class="doctorName">
							<p class="name">
								{{ personInfo.name }}
								<img @click="openVideoCall" src="@/assets/imgs/video.png" alt="" />
							</p>
							<p class="age"><span>年&emsp;&emsp;龄：</span> {{ personInfo.age }}</p>
							<p class="speciality"><span>专&emsp;&emsp;业：</span>{{ personInfo.speciality }}</p>
							<p class="practiceUnit one-text-overflow"><span>执业单位：</span> {{ personInfo.practiceUnit }}</p>
						</div>
					</div>
				</div>
				<div class="infoText">{{ personInfo.description }}</div>
				<div class="certificate" v-if="personInfo.documentList.length > 0">
					<p class="certificateTitle">资格证书</p>
					<p class="certificateList" v-for="(item, index) in personInfo.documentList" :key="index">
						<img src="@/assets/imgs/certificateIcon.png" alt="" />
						<span>{{ item.name }} &nbsp;—&nbsp;</span>
						<span>{{ item.number }}</span>
					</p>
				</div>
				<div class="nullImgData" v-else>未添加相关资格证书</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getAge } from '@/utils/formatTime.js';
export default {
	data() {
		return {
			personLoading: false,
			personInfo: {},
		};
	},
	props: {
		dataObj: {
			type: Object,
			default: () => ({}),
		},
	},
	methods: {
		closePerson() {
			this.$emit('closePerson');
		},
		// 获取数据
		getData(data) {
			if (!data.id) return this.$message.error('数据错误，请联系管理员');
			this.personLoading = true;
			// `/${data.id}.json`
			this.$http
				.get(`/management-api/v1/MedicalWorkers/${data.id}.json`)
				.then((res) => {
					if (res.data && res.data.success) {
						this.personLoading = false;
						let data = res.data.collection[0] || {};
						this.personInfo = data;
						this.personInfo.img = (data.attachmentDtoMap && data.attachmentDtoMap['COVER'] && data.attachmentDtoMap['COVER'][0].link) || '';
						this.personInfo.age = (data.birthDate && getAge(data.birthDate)) || '-';
						this.personInfo.speciality = data.practiceCategoryDictionaryItem?.title || '-';
						this.personInfo.practiceUnit = data.medicalOrganization?.title || '-';
						this.personInfo.documentList = data.notation ? JSON.parse(data.notation).document : [];
					}
				})
				.catch((err) => {});
		},
		openVideoCall() {
			console.log(this.personInfo, 'personInfo');
			this.$emit('openVideoCall', this.personInfo);
		},
		openVisitHistoryFn() {
			this.$emit('openVisitHistoryFn', this.personInfo);
		},
	},
};
</script>

<style lang="scss" scoped>
.person {
	width: 500px;
	height: 545px;
	background: url('../../../../assets/imgs/visitGroupBg.png') no-repeat center;
	background-size: 100% 100%;
	position: relative;
	&-title {
		height: 52px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 25px;
		background: url('../../../../assets/imgs/dialogTitleBg.png') no-repeat center;
		background-size: 100% 100%;
		color: #fff;
		.leftBox {
			margin-top: 6px;
			font-size: 22px;
			font-family: PangMenZhengDao;
			line-height: 22px;
			height: 25px;
			background: url('../../../../assets/imgs/线.png') no-repeat bottom;
			background-size: 100% 5px;
		}
		.rightBox {
			display: flex;
			align-items: center;
			margin-top: 6px;
			p {
				margin-right: 10px;
				color: #05feff;
				border-bottom: 1px solid #05feff;
				line-height: 20px;
				font-size: 16px;
				cursor: pointer;
			}
			img {
				width: 28px;
				height: 28px;
				cursor: pointer;
			}
		}
	}
	&-context {
		max-height: 490px;
		overflow-y: auto;
		.personInfo {
			padding: 0 18px;
			padding-top: 16px;
			&-doctor {
				background: rgba(12, 49, 94, 0.65);
				border-radius: 6px;
				display: flex;
				position: relative;
				.imgs {
					position: absolute;
					width: 130px;
					background-color: transparent;
					height: 148px;
					left: 18px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.doctorName {
					width: calc(100% - 180px);
					margin-left: 180px;
					padding: 15px 0;
					color: #fff;
					p {
						font-size: 14px;
						line-height: 14px;
						height: 14px;
						margin-bottom: 13px;
						&:last-child {
							margin-bottom: 0;
						}
					}
					.name {
						font-size: 16px;
						line-height: 16px;
						height: 16px;
						margin-top: 6px;
						margin-bottom: 28px;
						font-weight: bold;
						position: relative;
						img {
							width: 24px;
							height: 24px;
							position: absolute;
							right: 20px;
							top: -3px;
							cursor: pointer;
						}
					}
				}
			}
		}
		.infoText {
			line-height: 22px;
			padding: 12px 18px;
			color: #fff;
			border-bottom: 2px solid #153b6c;
		}
		.certificate {
			padding: 15px 18px;
			.certificateTitle {
				color: #c4e1fb;
				line-height: 14px;
				height: 14px;
				margin-bottom: 20px;
			}
			.certificateList {
				line-height: 16px;
				height: 16px;
				display: flex;
				align-items: center;
				color: #c4e1fb;
				font-size: 13px;
				margin-bottom: 12px;
				&:last-child {
					margin-bottom: 0;
				}
				img {
					width: 16px;
					height: 16px;
					margin-right: 12px;
				}
			}
		}
		.nullImgData {
			width: 100%;
			color: #fff;
			text-align: center;
			margin-top: 15px;
		}
	}
	.nullData {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		font-size: 16px;
	}
}
</style>