<template>
	<el-dialog title="" :visible.sync="dialogVisible" append-to-body width="100%" :show-close="false">
		<div class="content">
			<div class="visitInfo">
				<div class="visitInfo-title">
					<div class="leftBox">
						{{ dataObj.name }}
					</div>
					<div class="rightBox">
						<p @click="openVisitLineFn" v-if="dataObj.discriminationCode == 'T'">巡诊线路</p>
						<img @click="closeDialog" src="@/assets/imgs/visitCloseBtn.png" alt="" />
					</div>
				</div>
				<div class="visitInfo-context">
					<div class="basicInfo">
						<div class="basicInfo-time">
							<p>任务开始时间</p>
							<p style="color: #fff; margin-bottom: 5px">{{ dataObj.startTime }}</p>
						</div>
						<div class="basicInfo-time">
							<p>任务结束时间</p>
							<p style="color: #fff; margin-bottom: 5px">{{ dataObj.endTime }}</p>
						</div>
						<div class="basicInfo-time">
							<p>巡诊组成员</p>
							<p>
								<span v-for="(item, index) in dataObj.medicalWorkers" :key="index" @click="checkPerson(item)">{{ item.title || '' }}</span>
							</p>
						</div>
						<div class="basicInfo-time">
							<p>车辆信息</p>
							<p>
								<span v-for="car in dataObj.carList" :key="car.id" @click="checkCarType(car)">{{ (car.item && car.item.title) || '' }}</span>
							</p>
						</div>
						<div class="basicInfo-time">
							<p>设备信息</p>
							<p>
								<span v-for="dev in dataObj.devList" :key="dev.id" @click="checkDeviceType(dev)">{{ (dev.item && dev.item.title) || '' }}</span>
							</p>
						</div>
					</div>
					<div class="taskList">
						<div class="taskList-item" v-for="task in dataObj.taskList" :key="task.id">
							<p class="title">
								<span></span>
								<span>{{ (task.item && task.item.title) || '' }}</span>
							</p>
							<div class="text">
								{{ (task.item && task.item.description) || '' }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<PersonInfo
				ref="personInfo"
				v-if="openPerson"
				:dataObj="dataObj"
				@closePerson="
					openPerson = false;
					openVideoCall = false;
					openVisitHistory = false;
				"
				@openVideoCall="openVideoCallFn"
				@openVisitHistoryFn="openVisitHistoryFn"
			/>
			<DeviceInfo :discriminationCode="dataObj.discriminationCode" ref="deviceInfo" v-if="openDevice" @closeDevice="openDevice = false" />
			<CarInfo :discriminationCode="dataObj.discriminationCode" ref="carInfo" v-if="openCar" @closeCarInfo="openCar = false" />
			<VisitLine ref="visitLine" v-if="openVisitLine" @closeCarInfo="openVisitLine = false" :dataObj="dataObj" />
			<VideoCall ref="videoCall" :doctorInfo="doctorInfo" v-if="openVideoCall" @closeVideoCall="openVideoCall = false" />
			<VisitHistory
				ref="visitHistory"
				:dataObj="dataObj"
				v-if="openVisitHistory"
				:doctorInfo="doctorInfo"
				@closeHistoryInfo="openVisitHistory = false"
			/>
		</div>
	</el-dialog>
</template>

<script>
import PersonInfo from './personInfo.vue';
import DeviceInfo from './deviceInfo.vue';
import CarInfo from './carInfo.vue';
import VisitLine from './visitLine.vue';
import VideoCall from './videoCall.vue';
import VisitHistory from './visitHistory.vue';
export default {
	data() {
		return {
			dialogVisible: false,
			openPerson: false, //展开人员信息
			openDevice: false, //展开设备信息
			openCar: false, //展开车辆信息
			openVisitLine: false, //展开巡诊线路
			openVideoCall: false, //展开视频通话
			openVisitHistory: false, //展开历史巡诊
			dataObj: {}, //数据对象
			doctorInfo: {}, //医生信息
		};
	},
	components: {
		PersonInfo,
		DeviceInfo,
		CarInfo,
		VisitLine,
		VideoCall,
		VisitHistory,
	},

	methods: {
		init(data) {
			if (data.id) {
				this.dialogVisible = true;
				this.dataObj = { ...data };
				let arr = data.resourceAllocations
					?.map((item) => {
						return item.medicalWorkers;
					})
					.reduce((a, b) => {
						return a.concat(b);
					}, []);
				let newArr = [];
				arr.forEach((item) => {
					let index = newArr.findIndex((v) => v.id == item.id);
					if (index == -1) {
						newArr.push(item);
					}
				});
				this.dataObj.medicalWorkers = newArr;
				this.dataObj.carList = data.tourPlanConfigurationItemMap?.V;
				this.dataObj.devList = data.tourPlanConfigurationItemMap?.D;
				this.dataObj.taskList = data.tourPlanConfigurationItemMap?.M;
			}
		},
		// 选择人员
		checkPerson(item) {
			this.openPerson = true;
			this.$nextTick(() => {
				this.$refs.personInfo.getData(item);
			});
			this.openCar = false;
			this.openDevice = false;
			this.openVisitLine = false;
			this.openVideoCall = false;
			this.openVisitHistory = false;
		},
		// 关闭弹窗
		closeDialog() {
			this.dialogVisible = false;
			this.openCar = false;
			this.openPerson = false;
			this.openDevice = false;
			this.openVisitLine = false;
			this.openVideoCall = false;
			this.openVisitHistory = false;
		},
		openVisitLineFn() {
			this.openCar = false;
			this.openPerson = false;
			this.openDevice = false;
			this.openVisitLine = true;
			this.openVideoCall = false;
			this.openVisitHistory = false;
		},
		// 选择车辆
		checkCarType(item) {
			this.openCar = true;
			this.$nextTick(() => {
				this.$refs.carInfo.getData(item);
			});
			this.openPerson = false;
			this.openDevice = false;
			this.openVisitLine = false;
			this.openVideoCall = false;
			this.openVisitHistory = false;
		},
		// 选择设备
		checkDeviceType(item) {
			this.openDevice = true;
			this.$nextTick(() => {
				this.$refs.deviceInfo.getData(item);
			});
			this.openCar = false;
			this.openPerson = false;
			this.openVisitLine = false;
			this.openVideoCall = false;
			this.openVisitHistory = false;
		},
		openVideoCallFn(personInfo) {
			this.doctorInfo = JSON.parse(JSON.stringify(personInfo));
			this.openVideoCall = true;
			this.openVisitHistory = false;
			this.toAccid = '18780037519';
		},
		openVisitHistoryFn(personInfo) {
			this.doctorInfo = JSON.parse(JSON.stringify(personInfo));
			this.openVisitHistory = true;
			this.openVideoCall = false;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
	background-color: transparent;
	.el-dialog__header,
	.el-dialog__body {
		padding: 0 !important;
	}
	.content {
		width: 90%;
		display: flex;
		justify-content: center;
		margin: 0 auto;
	}
	.visitInfo {
		margin-right: 10px;
		width: 500px;
		height: 630px;
		background: url('../../../../assets/imgs/visitGroupBg.png') no-repeat center;
		background-size: 100% 100%;
		&-title {
			height: 52px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;
			background: url('../../../../assets/imgs/dialogTitleBg.png') no-repeat center;
			background-size: 100% 100%;
			color: #fff;
			.leftBox {
				margin-top: 6px;
				font-size: 22px;
				font-family: PangMenZhengDao;
				line-height: 22px;
				height: 25px;
				background: url('../../../../assets/imgs/线.png') no-repeat bottom;
				background-size: 100% 5px;
			}
			.rightBox {
				display: flex;
				align-items: center;
				margin-top: 6px;
				p {
					margin-right: 10px;
					color: #05feff;
					border-bottom: 1px solid #05feff;
					line-height: 20px;
					font-size: 16px;
					cursor: pointer;
				}
				img {
					width: 28px;
					height: 28px;
					cursor: pointer;
				}
			}
		}
		&-context {
			padding: 16px 0;
			max-height: 570px;
			overflow: auto;
			.basicInfo {
				border-bottom: 2px solid #153b6c;
				padding-bottom: 20px;
				margin-bottom: 20px;
				> div {
					padding: 0 23px;
					display: flex;
					margin-bottom: 20px;
					&:last-child {
						margin-bottom: 0;
					}
					p:first-child {
						width: 90px;
						text-align: start;
						margin-right: 30px;
						color: #a1c5d1;
					}
					p:last-child {
						flex: 1;
						color: #05feff;
						line-height: 14px;
						span {
							display: inline-block;
							margin-right: 16px;
							border-bottom: 1px solid #05feff;
							margin-bottom: 5px;
							cursor: pointer;
						}
					}
				}
			}
			.taskList {
				padding: 0 23px;
				&-item {
					margin-bottom: 15px;
					.title {
						line-height: 14px;
						height: 14px;
						margin-bottom: 15px;
						span:first-child {
							display: inline-block;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							background-color: #6ddaff;
							box-shadow: 0px 0px 6px #6ddaff;
							margin-right: 8px;
						}
						span {
							color: #a1c5d1;
						}
					}
					.text {
						color: #ffffff;
						line-height: 22px;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
::v-deepdiv::-webkit-scrollbar {
	width: 4px !important; /*滚动条宽度*/
	background-color: #031227 !important; /*滚动条背景颜色*/
	height: 394px !important; /*滚动条高度*/
}
::v-deepdiv::-webkit-scrollbar-thumb {
	box-shadow: 0px 1px 3px #203a66 inset !important; /*滚动条的内阴影*/
	border-radius: 10px !important; /*滚动条的圆角*/
	background-color: #203a66 !important; /*滚动条的背景颜色*/
}
</style>