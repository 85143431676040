<template>
	<div class="videoCall">
		<div class="videoCall-title clearfix">
			<img class="float-l" :src="doctorInfo.img" alt="" />
			<div class="videoCall-title-content float-l">
				<p>{{ doctorInfo.name }}</p>
				<p>{{ doctorInfo.phoneNumber }}</p>
				<p><img src="@/assets/imgs/gps.png" alt="" />{{ doctorInfo.practiceUnit }}</p>
			</div>
		</div>
		<CharRoom class="room" @onEnd="onEnd" :toAccid="toAccid"></CharRoom>
	</div>
</template>

<script>
import CharRoom from '../../charRoom.vue';
export default {
	components: {
		CharRoom,
	},
	data() {
		return {
			toAccid: '',
		};
	},
	props: {
		doctorInfo: {
			type: Object,
			default: {},
		},
	},
	watch: {
		doctorInfo: {
			handler(val) {
				this.toAccid = val.accId;
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		onEnd() {
			this.toAccid = '';
			this.$emit('closeVideoCall');
		},
	},
};
</script>

<style lang="scss" scoped>
.videoCall {
	background-color: #09244a;
	margin-left: 10px;
	padding-top: 5px;
	height: 415px;
	overflow: auto;
	&-title {
		padding: 8px;
		background: url('../../../../assets/imgs/videoCallTitleBg.png') no-repeat center;
		background-size: 100% 100%;
		img {
			margin-right: 10px;
			width: 60px;
			height: 60px;
		}
		&-content {
			p {
				color: #fff;
				font-size: 12px;
				margin-bottom: 10px;
				line-height: 12px;
				height: 12px;
				&:last-child {
					margin-bottom: 0;
					color: #0bf9fe;
					display: flex;
					align-items: center;
					img {
						margin-right: 5px;
						width: 12px;
						height: 13px;
					}
				}
			}
		}
	}
	&-content {
		padding: 8px 10px 13px;
		height: 334px;
		position: relative;
		img {
			width: 306px;
			height: 313px;
		}
		.maskLayer {
			position: absolute;
			color: #fff;
			top: 8px;
			left: 10px;
			width: 306px;
			height: 313px;
			background: rgba(0, 0, 0, 0.29);
			img {
				width: 46px;
				height: 24px;
				position: absolute;
				bottom: 10px;
				right: 13px;
				cursor: pointer;
			}
			p {
				font-size: 12px;
				line-height: 12px;
				height: 12px;
				&:first-child {
					margin-top: 16px;
					margin-left: 14px;
				}
				&:nth-child(2) {
					text-align: center;
					line-height: 285px;
					height: 285px;
				}
			}
		}
	}
	/deep/ .loading-text {
		line-height: normal;
	}
	.room {
		margin-top: 16px;
	}
}
</style>