<!-- 重点业务统计 -->
<template>
	<div class="importantKey w100">
		<div class="title">{{ (addressInfo.cityCode + '').length == 12 ? '重点疾病统计' : '重点业务统计' }}</div>
		<div class="tabClass" v-if="(addressInfo.cityCode + '').length != 12">
			<span v-for="item in tabsData" :key="item" :class="{ activeClass: checkVal === item }" @click="checkVal = item">{{ item }}</span>
		</div>
		<div class="nullData" v-if="showNoData" :style="{ height: chartHeight }">
			<img src="@/assets/imgs/noData.png" alt="" />
		</div>
		<div>
			<div
				v-show="checkVal == '重点疾病' && !showNoData"
				id="keyDiseases"
				style="width: 100%"
				:style="{ height: chartHeight }"
				v-loading="loading"
				element-loading-text="加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)"
			></div>
			<img
				v-if="checkVal == '重点疾病' && !showNoData && !loading"
				class="bussniessImg"
				:style="[{ top: (addressInfo.cityCode + '').length != 12 ? '46%' : '41%' }, { left: '50%' }]"
				src="@/assets/imgs/bussniessImg.png"
				alt=""
			/>
		</div>
		<PatrolCoverage
			ref="patrolCoverage"
			:checkVal="checkVal"
			v-if="!showNoData && checkVal != '重点疾病' && (addressInfo.cityCode + '').length != 12"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import * as echarts from 'echarts';
import PatrolCoverage from './patrolCoverage.vue';
import { random } from '@/utils';
export default {
	data() {
		return {
			tabsData: ['重点疾病', '巡诊覆盖率', '驻派覆盖率'],
			checkVal: '重点疾病',
			showNoData: false,
			loading: false,
			myCharts: null,
		};
	},
	components: {
		PatrolCoverage,
	},
	computed: {
		...mapState('mapData', ['addressInfo']),
		chartHeight() {
			if ((this.addressInfo.cityCode + '').length == 12) {
				return document.body.clientHeight - 788 > 400 ? document.body.clientHeight - 788 + 'px' : 400 + 'px';
			} else {
				return document.body.clientHeight - 788 > 365 ? document.body.clientHeight - 788 + 'px' : 365 + 'px';
			}
		},
	},
	watch: {
		checkVal: {
			handler(val) {
				this.showNoData = false;
				if (this.checkVal == '重点疾病') {
					this.$nextTick(() => {
						this.getKeyDiseases();
					});
				} else {
					this.$nextTick(() => {
						this.$refs.patrolCoverage.getPatrolCoverage();
					});
				}
			},
			deep: true,
			immediate: true,
		},
		addressInfo: {
			handler(val) {
				this.getKeyDiseases();
				if (val.cityCode.length == 12) {
					this.checkVal = '重点疾病';
				}
			},
			deep: true,
		},
	},
	mounted() {
		// this.getKeyDiseases();
		// window.addEventListener('resize', this.resizeHandler, false);
	},
	methods: {
		// 重点疾病
		async getKeyDiseases() {
			// this.loading = true;
			let total = 0;
			// let newDataArr = [
			// 	{
			// 		name: '高血压',
			// 		value: random(0, 100),
			// 		percentage: random(0, 60) + '%',
			// 	},
			// 	{
			// 		name: '糖尿病',
			// 		value: random(0, 100),
			// 		percentage: random(0, 60) + '%',
			// 	},
			// 	{
			// 		name: '精神障碍',
			// 		value: random(0, 100),
			// 		percentage: random(0, 40) + '%',
			// 	},
			// 	{
			// 		name: '肺结核',
			// 		value: random(0, 100),
			// 		percentage: random(0, 60) + '%',
			// 	},
			// 	{
			// 		name: '传染病',
			// 		value: random(0, 100),
			// 		percentage: random(0, 30) + '%',
			// 	},
			// ];
			let newDataArr = [];
			let code = !this.addressInfo.regionCode ? null : this.addressInfo.regionCode;
			let res = await this.$http.get(this.api['RegionPriorityDiseases#load'].href, { params: { regionCode: code } });
			if (res.data && res.data.success) {
				this.loading = false;
				let data = res.data.collection || [];
				this.showNoData = data.length === 0 ? true : false;
				if (data.length === 0) return;
				data.forEach((item) => {
					total += item.keyDiseaseNumbers;
				});

				newDataArr = data.map((item) => {
					return {
						value: item.keyDiseaseNumbers,
						name: item.keyDiseaseName,
						percentage: item.keyDiseaseNumbers != 0 ? (item.keyDiseaseNumbers / total) * 100 + '%' : 0,
					};
				});
			}
			var data2 = [];
			for (var i = 0; i < 5; i++) {
				data2.push(
					{
						value: 10,
						itemStyle: {
							normal: {
								color: 'rgba(255,255,255,0.4)', //透明伞形
							},
						},
					},
					{
						value: 5,
						itemStyle: {
							normal: {
								color: 'transparent', //透明伞形
							},
						},
					}
				);
			}
			// 虚线
			function _pie3() {
				let dataArr = [];
				for (var i = 0; i < 100; i++) {
					if (i % 2 === 0) {
						dataArr.push({
							name: (i + 1).toString(),
							value: 10,
							itemStyle: {
								normal: {
									color: 'rgb(255,255,255,.4)',
									borderWidth: 0,
									borderColor: 'rgba(0,0,0,0)',
								},
							},
						});
					} else {
						dataArr.push({
							name: (i + 1).toString(),
							value: 10,
							itemStyle: {
								normal: {
									color: 'rgba(0,0,0,0)',
									borderWidth: 0,
									borderColor: 'rgba(0,0,0,0)',
								},
							},
						});
					}
				}
				return dataArr;
			}
			this.myCharts = echarts.init(document.getElementById('keyDiseases'));
			this.myCharts.setOption({
				title: {},
				tooltip: {
					show: false,
				},
				legend: {
					data: newDataArr,
					formatter: function (name) {
						let target = 0;
						let percentage = 0;
						for (let i = 0; i < newDataArr.length; i++) {
							if (newDataArr[i].name === name) {
								target = newDataArr[i].value;
								percentage = newDataArr[i].percentage;
							}
						}
						var arr = ['{a|' + name + '}', '{b|' + target + '}', '{c|' + percentage + '}'];
						return arr.join('  ');
					},
					orient: 'vertical',
					icon: 'circle',
					bottom: '5%',
					top: '70%',
					itemGap: 20,
					itemWidth: 10,
					itemHeight: 8,
					textStyle: {
						rich: {
							a: {
								fontSize: 16,
								width: 80,
								color: '#fff',
								textAlign: 'left',
							},
							b: {
								fontSize: 16,
								width: 50,
								color: '#0BF9FE',
								textAlign: 'center',
							},
							c: {
								fontSize: 16,
								width: 40,
								color: '#0BF9FE',
								textAlign: 'center',
							},
						},
					},
				},
				series: [
					// 虚线
					{
						type: 'pie',
						zlevel: 3,
						silent: true,
						radius: ['30%', '31%'],
						center: ['50%', '36%'],
						label: {
							normal: {
								show: false,
							},
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
						data: _pie3(),
					},
					// 饼图
					{
						name: 'title',
						type: 'pie',
						hoverAnimation: false,
						legendHoverLink: false,
						radius: ['40%', '50%'],
						center: ['50%', '36%'],
						color: ['#5ACFE3', '#D4B02B', '#D97014', '#048AE2', '#DA464C'],
						label: {
							normal: {
								show: false,
							},
						},
						tooltip: {
							show: false,
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
						itemStyle: {
							normal: {
								shadowBlur: 15,
								shadowColor: 'rgba(0, 0, 0, 0.5)',
								borderColor: 'rgba(0,0,0,0)',
							},
						},
						data: newDataArr,
					},
					// 阴影圈
					{
						name: '',
						type: 'pie',
						hoverAnimation: false,
						legendHoverLink: false,
						radius: ['35%', '40%'],
						center: ['50%', '36%'],
						color: ['rgba(31,85,118,0.8)', 'rgba(85,111,92,0.8)', 'rgba(68,49,42,0.8)', 'rgba(4,60,111,0.8)', 'rgba(69,65,105,0.8)'],
						label: {
							normal: {
								show: false,
							},
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
						itemStyle: {
							normal: {
								borderColor: 'rgba(0,0,0,0)',
							},
						},
						data: newDataArr.map((item) => {
							return {
								value: item.value,
								name: '',
							};
						}),
					},
					{
						//外圈的边框
						type: 'pie',
						clockWise: false,
						radius: ['56%', '57%'],
						center: ['50%', '36%'],
						hoverAnimation: false,
						startAngle: 94, //起始角度
						tooltip: {
							show: false,
						},
						itemStyle: {
							normal: {
								label: {
									show: false,
								},
							},
						},
						data: data2,
					},
				],
			});
		},
		// resizeHandler() {
		// 	this.myCharts.resize();
		// },
	},
	destroyed() {
		// window.removeEventListener('resize', this.resizeHandler, false);
	},
};
</script>

<style lang="scss" scoped>
.importantKey {
	border: 2px solid #264471;
	box-sizing: border-box;
	position: relative;
	background: rgba(5, 41, 92, 0.6);

	.title {
		background: url('../../../assets/imgs/titleBg.png') no-repeat center;
		background-size: 100% 100%;
		line-height: 32px;
		padding-left: 16px;
		font-size: 16px;
	}
	.tabClass {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #213861;
		span {
			display: inline-block;
			width: calc(100% / 3);
			margin: 10px 0;
			text-align: center;
			font-size: 12px;
			color: #fff;
			cursor: pointer;
			border-right: 1px solid #213861;
			&:last-child {
				border-right: none;
			}
		}
		.activeClass {
			color: #00f0ff;
		}
	}
	.bussniessImg {
		position: absolute;

		transform: translate(-50%, -50%);
	}
	.nullData {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>