<template>
	<div
		class="visitLineInfo"
		v-loading="visitLoading"
		element-loading-text="加载中"
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.9)"
	>
		<div v-if="!visitLineInfo.id" class="nullData">暂无数据</div>
		<div v-else>
			<div class="visitLineInfo-title">
				<div class="leftBox">巡诊线路</div>
				<div class="rightBox">
					<img @click="closeCarInfo" src="@/assets/imgs/visitCloseBtn.png" alt="" />
				</div>
			</div>
			<div class="visitLineInfo-context">
				<div class="visitLineInfo-personList clearfix">
					<div class="personList float-l">
						<el-select v-model="checkedPersonList" multiple collapse-tags placeholder="请选择" :popper-append-to-body="false">
							<el-option v-for="item in personList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
						</el-select>
						<el-date-picker v-model="dateTime" type="date" placeholder="选择日期" :picker-options="secondPickerOptions" value-format="yyyy-MM-dd">
						</el-date-picker>
					</div>
					<div class="checkDate float-r">
						<el-button type="primary" @click="getData()" icon="el-icon-search">搜索</el-button>
					</div>
				</div>
				<p class="checkList">
					<span>已选</span>
					<span v-for="item in checkedPersonList" :key="item">{{ '“' + personList.find((v) => v.id == item).title + '”' }}</span>
					<span>{{ checkedPersonList.length }}名成员</span>
				</p>
			</div>
			<div class="map">
				<Map
					ref="map"
					:isShowText="true"
					:isShowPolyline="true"
					:isMultiSelect="true"
					:mapHeight="460"
					:isHomeMapLine="true"
					:centerPoint="centerPoint"
				></Map>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			visitLoading: false,
			visitLineInfo: {},
			checkedPersonList: [],
			personList: [],
			dateTime: '',
			secondPickerOptions: {
				disabledDate: (time) => {
					let t = new Date(time).getTime();
					if (this.visitLineInfo.endTime && this.visitLineInfo.startTime) {
						return t > new Date(this.visitLineInfo.endTime).getTime() || t < new Date(this.visitLineInfo.startTime).getTime();
					} else {
						return false;
					}
				},
			},
			centerPoint: [],
		};
	},
	props: {
		dataObj: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {
		Map: () => import('@/components/Map/index.vue'),
	},
	watch: {
		dataObj: {
			handler(val) {
				if (val.id) {
					this.visitLineInfo = JSON.parse(JSON.stringify(val));
					this.personList = JSON.parse(JSON.stringify(val.medicalWorkers));
					this.getData();
				}
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		closeCarInfo() {
			this.$emit('closeCarInfo');
		},
		// 获取数据
		getData() {
			this.visitLoading = true;
			let userInfoLatLon = JSON.parse(sessionStorage.getItem('userInfo'));
			this.centerPoint = [userInfoLatLon.longitude, userInfoLatLon.latitude];
			let params = {
				planId: this.visitLineInfo.id,
				touristId: this.checkedPersonList + '',
				discriminationCode: this.visitLineInfo.discriminationCode,
				startTime: this.dateTime ? this.dateTime + ' 00:00:00' : null,
				endTime: this.dateTime ? this.dateTime + ' 23:59:59' : null,
			};
			this.$http
				.get(this.api['TourWorkLogs#index'].href, { params })
				.then((res) => {
					if (res.data && res.data.success) {
						this.visitLoading = false;
						const { collection } = res.data;
						if (collection.length > 0) {
							this.$refs.map.pointMarker = [];
							this.$refs.map.polyline.path = [];
							this.$refs.map.pointMarker = collection.map((item, index) => {
								if (index == 0) {
									this.$refs.map.center = [item.longitude, item.latitude];
								}
								this.$refs.map.polyline.path.push([item.longitude, item.latitude]);
								return {
									lng: item.longitude,
									lat: item.latitude,
									text: item.name,
								};
							});
							this.$refs.map.point();
						}
					}
				})
				.catch((err) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.visitLineInfo {
	width: 500px;
	background: url('../../../../assets/imgs/visitGroupBg.png') no-repeat center;
	background-size: 100% 100%;
	position: relative;
	overflow: auto;
	&-title {
		height: 52px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 25px;
		background: url('../../../../assets/imgs/dialogTitleBg.png') no-repeat center;
		background-size: 100% 100%;
		color: #fff;
		.leftBox {
			margin-top: 6px;
			font-size: 22px;
			font-family: PangMenZhengDao;
			line-height: 22px;
			height: 25px;
			background: url('../../../../assets/imgs/线.png') no-repeat bottom;
			background-size: 100% 5px;
		}
		.rightBox {
			img {
				width: 28px;
				height: 28px;
				cursor: pointer;
				margin-top: 6px;
			}
		}
	}
	&-context {
		padding: 16px 23px 0;
		.personList {
			/deep/ .el-select {
				width: 140px;
				margin-right: 12px;
				.el-input__inner {
					height: 26px !important;
					line-height: 26px;
					border-radius: 4px;
					border: 2px solid #1e395e;
					background-color: #031d40;
					color: #c4e1fb;
					font-size: 12px;
				}
				.el-tag.el-tag--info {
					background-color: transparent;
					border-color: transparent;
					color: #c4e1fb;
				}
				.el-tag__close {
					color: #031d40;
					background-color: #c4e1fb;
				}
				.el-select__tags {
					max-width: 140px !important;
				}
			}
		}
		/deep/ .el-date-editor {
			width: 144px;
			.el-input__inner {
				width: 144px;
				line-height: 26px;
				height: 26px;
				background-color: #031d40;
				border: 2px solid #1e395e;
				box-sizing: border-box;
				color: #c4e1fb;
				font-size: 12px;
				.el-input__icon {
					line-height: 18px;
				}
			}
		}
		/deep/ .el-input__icon {
			line-height: 28px;
			font-size: 12px;
			color: #c4e1fb;
		}
		.checkDate {
			/deep/ .el-button {
				width: 62px;
				line-height: 26px;
				height: 26px;
				background-color: #05edf1;
				padding: 0;
				color: #0b2750;
				font-size: 12px;
			}
		}
		/deep/ .el-select-dropdown {
			background: #031d40;
			border-color: #1e395e;
			.el-select-dropdown__item {
				color: #c4e1fb;
				line-height: 26px;
				height: 26px;
				font-size: 12px;
				background-color: transparent;
				cursor: pointer;
				&:hover {
					background-color: transparent;
					color: #05edf1;
				}
			}
			.selected {
				color: #05edf1;
				background-color: transparent;
			}
			.el-scrollbar__wrap {
				overflow-x: auto !important;
			}
		}
		.checkList {
			color: #c4e1fb;
			margin-top: 5px;
			line-height: 16px;
			margin-bottom: 20px;
		}
	}
	.map {
		padding: 0 23px 23px;
	}
	.nullData {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		font-size: 16px;
	}
}
</style>