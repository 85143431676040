<template>
	<div>
		<el-row class="continarClass" style="margin: 0" :gutter="16">
			<el-col :span="6">
				<BasicDataTotal ref="basicDataTotal" class="mb16" />
				<PersonalTraining ref="personalTraining" class="mb16" />
				<ServiceAndActivite ref="basicDataTotal" class="mb16" />
			</el-col>
			<el-col :span="12">
				<MapData :mapType="7" :height="664" ref="mapData" :mapindex="0" />
			</el-col>
			<el-col :span="6">
				<BasicMedical ref="basicMedical" class="mb16" />
				<ImportantKey ref="importantKey" class="mb16" />
				<EventStatic ref="eventStatic" class="mb16" />
			</el-col>
		</el-row>
		<infoDialogVue ref="infoDialog" />
	</div>
</template>

<script>
import BasicDataTotal from './basicDataTotal.vue';
import PersonalTraining from './personalTraining.vue';
import BasicMedical from './basicMedical.vue';
import EventStatic from './eventStatic.vue';
import ServiceAndActivite from './serviceAndActivite.vue';
import ImportantKey from './importantKey.vue';
import MapData from './mapData/index.vue';
import { formatTimestamp } from '@/utils/formatTime.js';
import infoDialogVue from './twoLevelData/infoDialog.vue';
export default {
	name: 'home',
	components: {
		BasicDataTotal,
		PersonalTraining,
		BasicMedical,
		EventStatic,
		ServiceAndActivite,
		ImportantKey,
		MapData,
		infoDialogVue,
	},
	data() {
		return {
			currentTime: [],
			timer: null,
		};
	},
	mounted() {
		this.timer = null;
		this.timer = setInterval(() => {
			this.getCurrentTime();
		}, 1000);
		this.bus.$on('show_visitAndDispatch', (res) => {
			if (!res || !res.id) return this.$message.error('参数错误');
			this.$refs.infoDialog.init(res);
		});
	},
	methods: {
		getCurrentTime() {
			let data = new Date();
			let res = formatTimestamp(data);
			res = res.split(' ');
			this.currentTime = [res[0], res[1]];
		},
	},
	watch: {
		$route: {
			handler(val) {
				if (val) {
					this.$store.state.themeConfig.themeConfig.isCollapse = true;
				}
			},
			deep: true,
			immediate: true,
		},
	},
	destroyed() {
		clearInterval(this.timer);
	},
};
</script>
<style lang="scss" scoped>
.images {
	position: relative;
	top: -8px;
	.titleLineLight {
		position: absolute;
		left: 0;
		top: -35px;
	}
}
.header-content {
	height: 78px;
	line-height: 78px;
	padding: 0 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.left {
		display: flex;
		align-items: center;
		.linghtClass {
			display: inline-block;
			width: 15px;
			height: 15px;
			background-color: #fff;
			box-shadow: 0 0 10px #0db1f4, 0 0 20px #0db1f4, 0 0 30px #0db1f4, 0 0 40px #0db1f4, 0 0 70px #0db1f4, 0 0 80px #0db1f4, 0 0 100px #0db1f4,
				0 0 150px #0db1f4;
			margin-right: 16px;
		}
		.subTitle {
			padding-top: 10px;
			margin-left: 16px;
		}
	}
}
.continarClass {
	color: #fff;
	padding: 0 20px;
}
/deep/ .el-row {
	margin: 0;
}
/deep/ div::-webkit-scrollbar {
	width: 4px !important; /*滚动条宽度*/
	background-color: #031227 !important; /*滚动条背景颜色*/
	height: 394px !important; /*滚动条高度*/
}
/deep/ div::-webkit-scrollbar-thumb {
	box-shadow: 0px 1px 3px #203a66 inset !important; /*滚动条的内阴影*/
	border-radius: 10px !important; /*滚动条的圆角*/
	background-color: #203a66 !important; /*滚动条的背景颜色*/
}
</style>