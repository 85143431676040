<!-- 人才培养培训统计 / 电子健康档案统计 -->
<template>
	<div class="personalClass">
		<div class="title">
			<p>
				{{ (addressInfo.cityCode + '').length == 12 ? '电子健康档案统计' : '人才培养培训统计' }}
			</p>
			<div class="checkRadioClass" v-if="(addressInfo.cityCode + '').length != 12">
				<el-radio v-model="radio" label="1">人才数据</el-radio>
				<el-radio v-model="radio" label="2">培训数据</el-radio>
			</div>
		</div>
		<div class="tableClass">
			<div v-if="(addressInfo.cityCode + '').length != 12">
				<el-table v-if="radio == 1" :data="personalTableData" :height="tableHeight" style="width: 100%">
					<el-table-column
						prop="regionName"
						:label="
							!addressInfo.regionCode
								? '省份'
								: (addressInfo.regionCode + '').length == 2
								? '市'
								: (addressInfo.regionCode + '').length == 4
								? '区县'
								: (addressInfo.regionCode + '').length == 6
								? '乡镇'
								: (addressInfo.regionCode + '').length == 9
								? '乡村'
								: ''
						"
						show-overflow-tooltip
					>
					</el-table-column>
					<el-table-column label="全科医生" min-width="72" show-overflow-tooltip prop="doctor1"> </el-table-column>
					<el-table-column label="临床医师" min-width="72" show-overflow-tooltip prop="doctor2"> </el-table-column>
					<el-table-column label="中医医师" min-width="72" show-overflow-tooltip prop="doctor3"> </el-table-column>
					<el-table-column label="护士" min-width="72" show-overflow-tooltip prop="doctor4"> </el-table-column>
				</el-table>
				<div v-else>
					<el-table :data="traningTableData" :height="tableHeight">
						<el-table-column
							prop="regionName"
							:label="
								!addressInfo.regionCode
									? '省份'
									: (addressInfo.regionCode + '').length == 2
									? '市'
									: (addressInfo.regionCode + '').length == 4
									? '区县'
									: (addressInfo.regionCode + '').length == 6
									? '乡镇'
									: (addressInfo.regionCode + '').length == 9
									? '乡村'
									: ''
							"
						>
						</el-table-column>
						<el-table-column prop="trainingTypeDictionaryItemName" label="培训类型"> </el-table-column>
						<el-table-column prop="trainingTimes" label="培训次数"> </el-table-column>
						<el-table-column prop="trainingPersonTimes" label="培训人数"> </el-table-column>
					</el-table>
				</div>
			</div>
			<div v-else>
				<el-table :data="healthTotalTable" :height="tableHeight">
					<el-table-column prop="name" label="姓名"> </el-table-column>
					<el-table-column label="性别">
						<template slot-scope="scope">
							{{ (scope.row.genderDictionaryItem && scope.row.genderDictionaryItem.title) || '-' }}
						</template>
					</el-table-column>
					<el-table-column label="年龄" class="healthAge">
						<template slot-scope="scope">
							{{ (scope.row.birthDate && getAge(scope.row.birthDate)) || '-' }}
						</template>
					</el-table-column>
					<el-table-column label="创建时间" class="healthText" prop="createTime" width="140"> </el-table-column>
				</el-table>
			</div>
		</div>
		<div v-if="radio != 1 && (addressInfo.cityCode + '').length != 12" class="checkTabsClass">
			<div class="tabs" :class="{ activeClass: checkTabs == item.id }" v-for="item in tabDatas" :key="item.id" @click="changeTabs(item)">
				<span>{{ item.title }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { getAge } from '@/utils/formatTime.js';
import { random } from '@/utils';
export default {
	data() {
		return {
			radio: '1',
			personalTableData: [], //人才统计
			traningTableData: [], //培训统计
			checkTabs: '',
			tabDatas: [],
			healthTotalTable: [], //电子健康档案统计
		};
	},
	computed: {
		...mapState('mapData', ['addressInfo', 'mapDataArr']),
		tableHeight() {
			if (this.radio == 1) {
				return document.body.clientHeight - 752 > 375 ? document.body.clientHeight - 752 : 375;
			} else {
				return document.body.clientHeight - 805 > 335 ? document.body.clientHeight - 805 : 335;
			}
		},
	},
	mounted() {
		this.init();
		this.getTrainType();
	},
	watch: {
		radio: {
			handler(val) {
				this.init();
			},
		},
		addressInfo: {
			handler(val) {
				if ((val.cityCode + '').length != 12) {
					this.init();
				} else {
					this.getUserInfoList();
				}
			},
			deep: true,
			immediate: true,
		},
		mapDataArr: {
			handler(val) {
				this.init();
			},
			deep: true,
		},
	},
	methods: {
		getAge,
		// 获取培训类型
		getTrainType() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'TRAINING_TYPE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.tabDatas = res.data.collection || [];
						this.checkTabs = this.tabDatas[0].id;
					}
				})
				.catch((e) => {});
		},
		//切换tabs
		changeTabs(item) {
			this.checkTabs = item.id;
			this.init();
		},
		init() {
			if (this.radio == 1) {
				this.personalTableData = this.mapDataArr.map((v) => {
					return {
						regionName: v.regionName,
						doctor1: random(0, 30),
						doctor2: random(0, 50),
						doctor3: random(0, 10),
						doctor4: random(0, 100),
					};
				});
				// let res = await this.$http.get(this.api.getTalentDoctor + '.json', { params: { regionCode: this.addressInfo.regionCode } });
				// if (res.data && res.data.success) {
				// 	this.personalTableData = res.data.collection || [];
				// 	console.log(this.personalTableData, 'this.personalTableData');
				// }
			} else {
				// let res = await this.$http.get(this.api.getTalentTraining + '.json', {
				// 	params: { trainingTypeDictionaryItemId: this.checkTabs, regionCode: this.addressInfo.regionCode },
				// });
				// if (res.data && res.data.success) {
				// 	this.traningTableData = res.data.collection || [];
				// }
				// console.log(this.traningTableData, 'this.traningTableData');
				this.traningTableData = this.mapDataArr.map((v) => {
					return {
						regionName: v.regionName,
						trainingTypeDictionaryItemName: '巡诊驻派',
						trainingTimes: random(0, 30),
						trainingPersonTimes: random(0, 100),
					};
				});
			}
		},
		getUserInfoList() {
			// this.healthTotalTable =
			let data = {
				size: 99999,
				current: 1,
			};
			this.$http
				.get(this.api['People#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.healthTotalTable = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.personalClass {
	border: 2px solid #264471;
	box-sizing: border-box;

	.title {
		background: url('../../../assets/imgs/titleBg.png') no-repeat center;
		background-size: 100% 100%;
		line-height: 32px;
		padding-left: 16px;
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.checkRadioClass {
			width: 170px;
			margin-right: 16px;
			/deep/ .el-radio {
				margin-right: 16px;
				&:last-child {
					margin-right: 0;
				}
			}
			/deep/ .el-radio__label {
				padding-left: 7px;
				color: #cceeff;
			}
			/deep/ .el-radio__input.is-checked .el-radio__inner {
				background: #0d386b;
				border: 1px solid #225598;
				&::after {
					background: #00f0ff;
				}
			}
			/deep/ .el-radio__inner {
				background: #0d386b;
				border: 1px solid #225598;
			}
		}
	}
	.tableClass {
		background: linear-gradient(to bottom, #0a2a57, #092448);
		padding: 13px 11px;
		/deep/ .el-table__body-wrapper {
			background: linear-gradient(to top, #04142d, #0a2a57);
		}
	}
	.checkTabsClass {
		display: flex;
		align-items: center;
		.tabs {
			border-top: 1px solid #213861;
			border-right: 1px solid #213861;
			box-sizing: border-box;
			line-height: 32px;
			cursor: pointer;
			width: 20%;
			text-align: center;
			span {
				font-size: 12px;
			}
		}
		.activeClass {
			line-height: 36px;
			border: 1px solid #213861;
			border-radius: 6px 6px 0 0;
			span {
				color: #00f0ff;
			}
		}
	}
	/deep/ .el-table {
		.el-table__cell {
			background-color: #153b6b;
			color: #fff;
			padding: 5px 0;
			text-align: center;
			font-size: 12px;
		}
		.el-table__empty-block {
			background-color: #153b6b;
			.el-table__empty-text {
				color: #fff;
			}
		}
		.el-table__header {
			width: auto !important;
		}
	}
	/deep/ .el-table td.el-table__cell,
	/deep/ .el-table th.el-table__cell.is-leaf {
		border-color: #031126 !important;
	}
	/deep/ .el-table--border::after,
	/deep/ .el-table--group::after,
	/deep/ .el-table::before {
		background-color: #031126 !important;
	}
	/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
		background-color: #08203e !important;
	}
	/deep/ .el-table tr:nth-child(2n-1) {
		td {
			background-color: #0d2e5a !important;
		}
	}
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
	width: 4px !important; /*滚动条宽度*/
	background-color: #031227 !important; /*滚动条背景颜色*/
	height: 394px !important; /*滚动条高度*/
}
/*定义滑块 内阴影+圆角*/
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
	box-shadow: 0px 1px 3px #203a66 inset !important; /*滚动条的内阴影*/
	border-radius: 10px !important; /*滚动条的圆角*/
	background-color: #203a66 !important; /*滚动条的背景颜色*/
}
/deep/ .el-table--scrollable-x .el-table__body-wrapper {
	overflow-x: hidden !important;
}
</style>